import "./src/autosize"
import "./src/countup"
import "./src/input-mask"
import "./src/dropdown"
import "./src/tooltip"
import "./src/popover"
import "./src/switch-icon"
import "./src/tab"
import "./src/toast"

import * as bootstrap from "bootstrap"
import * as tabler from "./src/tabler"

globalThis.bootstrap = bootstrap
globalThis.tabler = tabler
